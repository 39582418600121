export const BASE_API = {
  // Auth -(Account Management)
  login: "/user/login",

  sbu: "/sbu",

  //.................. dealer ..................
  dealer: "/dealer",

  //.................. Collateral ..................
  // (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  bank: "/bank-guarantee",
  cheque: "/cheque",

  //.................. product manage ..................
  // (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  productCategory: "/product-category",
  subCategory: "/product-sub-category",
  product: "/product",
  createUnit: "/create-unit",

  // .................GEO start..................
  // division (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  division: "/division",
  // district (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  district: "/district",
  // police station (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  policeStation: "/police-station",
  // region (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  region: "/region",
  // area (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  area: "/area",
  // territory (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  territory: "/territory",
  // .................GEO End..................

  //.................. gift manage ..................
  // (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  giftItem: "/gift-item",
  giftAssign: "/gift-assign",

  //.................. wear house ..................
  // (create, update/:id, delete/:id, singleGet/:id, getAll/data?query)
  wearHouse: "/wear_house",

  // Image -(File Upload)
  fileUpload: `/tower/upload-image`,
};
