import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CategoryDataParams,
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { SubCategoryData } from "../../../types/redux/manageProduct/subCategory";

// create
export const createSubCategoryAsync = createAsyncThunk<
  null,
  PostRequest<SubCategoryData, CommonOthers>
>(
  "admin-SubCategory/createSubCategory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.subCategory, data);

      reRender(true);
      toast("success", "SubCategory create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Sub Category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllSubCategoryAsync = createAsyncThunk<
  GetCommonData<SubCategoryData>,
  Params<CategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-SubCategory/getAllSubCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.subCategory}/data`, {
        params,
      });
      console.log("data", data);
      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Sub Category fail");
      return rejectWithValue(error);
    }
  }
);

// get all by filter
export const getAllSubCategoryByFilterAsync = createAsyncThunk<
  GetCommonData<SubCategoryData>,
  Params<CategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-SubCategory/getAllSubCategoryByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.subCategory}/data`, {
        params,
      });

      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Sub Category fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateSubCategoryAsync = createAsyncThunk<
  null,
  PostRequest<SubCategoryData, GetSingleDataParams>
>(
  "admin-SubCategory/updateSubCategory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.subCategory}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Sub Category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Sub Category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteSubCategoryAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-SubCategory/deleteSubCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.subCategory}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Sub Category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Sub Category delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleSubCategoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singleSubCategory", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.subCategory}/${params?.id}`);

    return data.data?.categories as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Sub Category data get fail");
    return rejectWithValue(error);
  }
});
