import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./features/appSlice";
import areaSlice from "./features/Area/AreaSlice";
import loginSlice from "./features/auth/loginSlice";
import bankSlice from "./features/Collateral/Bank/BankSlice";
import chequeSlice from "./features/Collateral/Cheque/ChequeSlice";
import dealerSlice from "./features/Dealers/DealersSlice";
import districtSlice from "./features/District/DistrictSlice";
import divisionSlice from "./features/Division/DivisionSlice";
import giftAssignSlice from "./features/Gift/GiftAssign/GiftAssignSlice";
import GiftItemSlice from "./features/Gift/GiftItem/GiftItemSlice";
import PoliceStationSlice from "./features/PoliceStation/PoliceStationSlice";
import productCategorySlice from "./features/ProductCategory/ProductCategorySlice";
import regionSlice from "./features/Region/RegionSlice";
import sbuSlice from "./features/Sbu/SbuSlice";
import subCategorySlice from "./features/SubCategory/SubCategorySlice";
import territorySlice from "./features/Territory/TerritorySlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    sbu: sbuSlice.reducer,
    division: divisionSlice.reducer,
    district: districtSlice.reducer,
    PoliceStation: PoliceStationSlice.reducer,
    region: regionSlice.reducer,
    area: areaSlice.reducer,
    territory: territorySlice.reducer,
    category: productCategorySlice.reducer,
    subCategory: subCategorySlice.reducer,
    giftItem: GiftItemSlice.reducer,
    giftAssign: giftAssignSlice.reducer,
    bank: bankSlice.reducer,
    cheque: chequeSlice.reducer,
    dealer: dealerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
