import { createSlice } from "@reduxjs/toolkit";
import { DealerInitialStatus } from "../../../types/redux/dealers/dealers";
import {
  createDealerAsync,
  deleteDealerAsync,
  getAllDealerAsync,
  getAllDealerByFilterAsync,
  singDealerAsync,
  updateDealerAsync,
} from "./DealersApi";

const initialState: DealerInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  dealers: [],
  dealerByFilter: [],
  singleDealerData: null,
  countDealer: 0,
};

const dealerSlice = createSlice({
  name: "admin-dealer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createDealerAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createDealerAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createDealerAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllDealerAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllDealerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.dealers = action.payload.data;
        state.countDealer = action.payload.length;
      })
      .addCase(getAllDealerAsync.rejected, (state) => {
        state.loading = false;
        state.dealers = [];
        state.countDealer = 0;
      });

    // get all by filter
    builder
      .addCase(getAllDealerByFilterAsync.pending, (state) => {})
      .addCase(getAllDealerByFilterAsync.fulfilled, (state, action) => {
        state.dealers = action.payload.data;
      })
      .addCase(getAllDealerByFilterAsync.rejected, (state) => {
        state.dealers = [];
        state.countDealer = 0;
      });

    // update
    builder
      .addCase(updateDealerAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateDealerAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateDealerAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteDealerAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteDealerAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteDealerAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singDealerAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleDealerData = null;
      })
      .addCase(singDealerAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleDealerData = action.payload;
      })
      .addCase(singDealerAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleDealerData = null;
      });
  },
});

export default dealerSlice;
