import { Button, Layout, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import SidebarBashIcon from "../../../assets/icons/SidebarBashIcon";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";

type Props = {
  collapsed: boolean;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
};

export default function SidebarContent({
  handleShowSidebar,
  collapsed,
  handleCollapsed,
}: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className="site-layout transition-all">
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          position: "sticky",
          top: 0,
          zIndex: 999,
        }}
      >
        <div className="flex items-center max-h-full justify-between pr-5">
          <div className="w-16 h-16 flex justify-center items-center">
            <Button
              type="text"
              icon={<SidebarBashIcon />}
              onClick={handleShowSidebar}
              className="md:hidden flex justify-center items-center"
              size="large"
            />
            <Button
              type="text"
              icon={<SidebarBashIcon />}
              onClick={handleCollapsed}
              className="hidden md:flex justify-center items-center"
              size="large"
            />
          </div>

          <div className="flex items-center gap-3">
            <NotificationMenu />
            <ProfileMenu />
          </div>
        </div>
      </Header>

      <Content
        style={{
          padding: 24,
          minHeight: 280,
          backgroundColor: "#F5F6F7",
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
}
