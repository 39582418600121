import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  GetPoliceStationSingleData,
  PoliceStationData,
  PoliceStationDataParams,
} from "../../../types/redux/geo/policeStation";

// create
export const createPoliceStationAsync = createAsyncThunk<
  null,
  PostRequest<PoliceStationData, CommonOthers>
>(
  "admin-policeStation/createPoliceStation",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.policeStation, data);

      reRender(true);
      toast("success", "Police Station create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Police Station create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllPoliceStationAsync = createAsyncThunk<
  GetCommonData<PoliceStationData>,
  Params<PoliceStationDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-policeStation/getAllPoliceStation",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.policeStation}/data`, {
        params,
      });

      return {
        data: data.data?.policeStations,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Police Station fail");
      return rejectWithValue(error);
    }
  }
);

// get all by filter
export const getAllPoliceStationByFilterAsync = createAsyncThunk<
  GetCommonData<PoliceStationData>,
  Params<PoliceStationDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-policeStation/getAllPoliceStationByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.policeStation}/data`, {
        params,
      });

      return {
        data: data.data?.policeStations,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get PoliceStation fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updatePoliceStationAsync = createAsyncThunk<
  null,
  PostRequest<PoliceStationData, GetSingleDataParams>
>(
  "admin-policeStation/updatePoliceStation",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.policeStation}/${id}`, data);

      reRender && reRender(true);
      toast("success", "PoliceStation update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "PoliceStation update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deletePoliceStationAsync = createAsyncThunk<
  null,
  Params<GetPoliceStationSingleData>
>(
  "admin-policeStation/deletePoliceStation",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.policeStation}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Police Station delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Police Station delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singPoliceStationAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-policeStation/singPoliceStationData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.policeStation}/${params?.id}`);

      return data.data?.policeStations as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single Police Stations data get fail");
      return rejectWithValue(error);
    }
  }
);
