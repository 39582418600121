import NotificationIcon from "../../../assets/icons/NotificationIcon";
import CollateralIcon from "../../../assets/icons/sidebar/CollateralIcon";
import DashboardIcon from "../../../assets/icons/sidebar/DashboardIcon";
import DealersManageIcon from "../../../assets/icons/sidebar/DealersManageIcon";
import GeoIcon from "../../../assets/icons/sidebar/GeoIcon";
import GiftManageIcon from "../../../assets/icons/sidebar/GiftManageIcon";
import LedgerReportsIcon from "../../../assets/icons/sidebar/LedgerReportsIcon";
import OrdersManageIcon from "../../../assets/icons/sidebar/OrdersManageIcon";
import ProductsManageIcon from "../../../assets/icons/sidebar/ProductsManageIcon";
import SettingsIcon from "../../../assets/icons/sidebar/SettingsIcon";
import SubmenuIcon from "../../../assets/icons/sidebar/SubmenuIcon";
import WearHouseIcon from "../../../assets/icons/sidebar/WearHouseIcon";

const AllSidebarMenu = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: DashboardIcon,
    role: ["ADMIN"],
  },
  {
    name: "Dealers Manage",
    path: "/admin/dealers-manage",
    icon: DealersManageIcon,
    role: ["ADMIN"],
  },
  {
    name: "Collateral",
    path: "/admin/collateral",
    icon: CollateralIcon,
    role: ["ADMIN"],
    children: [
      {
        name: "Bank Guarantee",
        path: "/admin/collateral/bank-guarantee",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Cheque",
        path: "/admin/collateral/cheque",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
    ],
  },
  {
    name: "Products Manage",
    path: "/admin/products-manage",
    icon: ProductsManageIcon,
    role: ["ADMIN"],
    children: [
      {
        name: "Category",
        path: "/admin/products-manage/category",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Sub Category",
        path: "/admin/products-manage/sub-category",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Products",
        path: "/admin/products-manage/products",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Create Unit",
        path: "/admin/products-manage/units",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
    ],
  },
  {
    name: "Gift Manage",
    path: "/admin/gift-manage",
    icon: GiftManageIcon,
    role: ["ADMIN"],
    children: [
      {
        name: "Gift Items",
        path: "/admin/gift-manage/gift-items",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Gift Assign",
        path: "/admin/gift-manage/gift-assign",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
    ],
  },
  {
    name: "Orders Manage",
    path: "/admin/orders-manage",
    icon: OrdersManageIcon,
    role: ["ADMIN"],
  },
  {
    name: "Wear house",
    path: "/admin/wear-house",
    icon: WearHouseIcon,
    role: ["ADMIN"],
  },
  {
    name: "Notification",
    path: "/admin/notification",
    icon: NotificationIcon,
    role: ["ADMIN"],
  },
  {
    name: "Ledger Reports",
    path: "/admin/ledger-report",
    icon: LedgerReportsIcon,
    role: ["ADMIN"],
  },
  // GEO Information
  {
    name: "Geo Info",
    path: "/admin/geo",
    icon: GeoIcon,
    role: ["ADMIN"],
    children: [
      {
        name: "Division",
        path: "/admin/geo/division",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "District",
        path: "/admin/geo/district",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Police Station",
        path: "/admin/geo/police-station",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Region",
        path: "/admin/geo/region",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Area",
        path: "/admin/geo/area",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
      {
        name: "Territory",
        path: "/admin/geo/territory",
        icon: SubmenuIcon,
        role: ["ADMIN"],
      },
    ],
  },
  {
    name: "Settings",
    path: "/admin/settings",
    icon: SettingsIcon,
    role: ["ADMIN"],
  },
];

export default AllSidebarMenu;
