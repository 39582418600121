import { createSlice } from "@reduxjs/toolkit";

import { GiftAssignInitialStatus } from "../../../../types/redux/gift/giftAssign";
import {
  createGiftAssignAsync,
  deleteGiftAssignAsync,
  getAllGiftAssignAsync,
  getAllGiftAssignByFilterAsync,
  singGiftAssignAsync,
  updateGiftAssignAsync,
} from "./GiftAssignApi";

const initialState: GiftAssignInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  giftAssign: [],
  giftAssignByFilter: [],
  singleGiftAssignData: null,
  countGiftAssign: 0,
};

const giftAssignSlice = createSlice({
  name: "admin-division",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createGiftAssignAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createGiftAssignAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createGiftAssignAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllGiftAssignAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllGiftAssignAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.giftAssign = action.payload.data;
        state.countGiftAssign = action.payload.length;
      })
      .addCase(getAllGiftAssignAsync.rejected, (state) => {
        state.loading = false;
        state.giftAssign = [];
        state.countGiftAssign = 0;
      });

    // get all by filter
    builder
      .addCase(getAllGiftAssignByFilterAsync.pending, (state) => {})
      .addCase(getAllGiftAssignByFilterAsync.fulfilled, (state, action) => {
        state.giftAssign = action.payload.data;
      })
      .addCase(getAllGiftAssignByFilterAsync.rejected, (state) => {
        state.giftAssign = [];
        state.countGiftAssign = 0;
      });

    // update
    builder
      .addCase(updateGiftAssignAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateGiftAssignAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateGiftAssignAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteGiftAssignAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteGiftAssignAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteGiftAssignAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singGiftAssignAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleGiftAssignData = null;
      })
      .addCase(singGiftAssignAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleGiftAssignData = action.payload;
      })
      .addCase(singGiftAssignAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleGiftAssignData = null;
      });
  },
});

export default giftAssignSlice;
