import { lazy } from "react";
import { RouteType } from "../types";

const SubCategory = lazy(
  () => import("../pages/admin/ProductsManage/SubCategory")
);
const Area = lazy(() => import("../pages/admin/Geo/Area"));
const District = lazy(() => import("../pages/admin/Geo/District"));
const Division = lazy(() => import("../pages/admin/Geo/Division"));
const PoliceStation = lazy(() => import("../pages/admin/Geo/PoliceStation"));
const Region = lazy(() => import("../pages/admin/Geo/Region"));
const Territory = lazy(() => import("../pages/admin/Geo/Territory"));
const NotFound = lazy(() => import("../pages/NotFound"));
const AdminLogin = lazy(() => import("../pages/admin/Login"));
const AdminDashboard = lazy(() => import("../pages/admin/Dashboard"));
const Cheque = lazy(() => import("../pages/admin/Collateral/Cheque"));
const Category = lazy(() => import("../pages/admin/ProductsManage/Category"));
const Products = lazy(() => import("../pages/admin/ProductsManage/Products"));
const Units = lazy(() => import("../pages/admin/ProductsManage/Units"));
const GiftItems = lazy(() => import("../pages/admin/GiftManage/GiftItems"));
const GiftAssign = lazy(() => import("../pages/admin/GiftManage/GiftAssign"));
const LedgerReport = lazy(() => import("../pages/admin/Report/LedgerReport"));
const WearHouse = lazy(() => import("../pages/admin/WearHouse/WearHouse"));
const Settings = lazy(() => import("../pages/admin/Settings/Settings"));
const CreateDealer = lazy(
  () => import("../pages/admin/DealersManage/CreateDealer")
);
const DealersManage = lazy(
  () => import("../pages/admin/DealersManage/DealersManage")
);
const BankGuarantee = lazy(
  () => import("../pages/admin/Collateral/BankGuarantee")
);
const OrdersManage = lazy(
  () => import("../pages/admin/OrdersManage/OrdersManage")
);
const OrderDetails = lazy(
  () => import("../pages/admin/OrdersManage/OrderDetails")
);
const Notification = lazy(
  () => import("../pages/admin/Notification/Notification")
);

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
    name: "Dashboard",
  },

  // Dealer management
  {
    path: "/admin/dealers-manage",
    element: DealersManage,
    roles: ["ADMIN"],
    name: "Dealers Manage",
  },
  {
    path: "/admin/dealers-manage/create-dealer",
    element: CreateDealer,
    roles: ["ADMIN"],
    name: "Dealers Manage - write",
  },

  //Collateral
  {
    path: "/admin/collateral/bank-guarantee",
    element: BankGuarantee,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/collateral/cheque",
    element: Cheque,
    roles: ["ADMIN"],
  },

  // Products manage
  {
    path: "/admin/products-manage/category",
    element: Category,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/products-manage/sub-category",
    element: SubCategory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/products-manage/products",
    element: Products,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/products-manage/units",
    element: Units,
    roles: ["ADMIN"],
  },

  // Gift management
  {
    path: "/admin/gift-manage/gift-items",
    element: GiftItems,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/gift-manage/gift-assign",
    element: GiftAssign,
    roles: ["ADMIN"],
  },

  // Order management
  {
    path: "/admin/orders-manage",
    element: OrdersManage,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/orders-manage/:id",
    element: OrderDetails,
    roles: ["ADMIN"],
  },

  // Wear house
  {
    path: "/admin/wear-house",
    element: WearHouse,
    roles: ["ADMIN"],
  },

  // notification
  {
    path: "/admin/notification",
    element: Notification,
    roles: ["ADMIN"],
  },

  // ledger report
  {
    path: "/admin/ledger-report",
    element: LedgerReport,
    roles: ["ADMIN"],
  },

  //GEO
  {
    path: "/admin/geo/division",
    element: Division,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo/district",
    element: District,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo/police-station",
    element: PoliceStation,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo/region",
    element: Region,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo/area",
    element: Area,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo/territory",
    element: Territory,
    roles: ["ADMIN"],
  },

  // settings
  {
    path: "/admin/settings",
    element: Settings,
    roles: ["ADMIN"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
