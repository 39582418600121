import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CategoryData,
  CategoryDataParams,
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";

// create
export const createProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<CategoryData, CommonOthers>
>(
  "admin-ProductCategory/createProductCategory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.productCategory, data);

      reRender(true);
      toast("success", "ProductCategory create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product Category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllProductCategoryAsync = createAsyncThunk<
  GetCommonData<CategoryData>,
  Params<CategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-ProductCategory/getAllProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.productCategory}/data`, {
        params,
      });
      console.log("data", data);
      return {
        data: data.data,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Product Category fail");
      return rejectWithValue(error);
    }
  }
);

// get all by filter
export const getAllProductCategoryByFilterAsync = createAsyncThunk<
  GetCommonData<CategoryData>,
  Params<CategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-ProductCategory/getAllProductCategoryByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.productCategory}/data`, {
        params,
      });

      return {
        data: data.data,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Product Category fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<CategoryData, GetSingleDataParams>
>(
  "admin-ProductCategory/updateProductCategory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.productCategory}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Product Category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product Category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteProductCategoryAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-ProductCategory/deleteProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.productCategory}/${params?.id}`);

      params?.reRender && params?.reRender(true);
      toast("success", "Product Category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Product Category delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleProductCategoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-geo/singleProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.productCategory}/${params?.id}`);

      return data.data as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single Product Category data get fail");
      return rejectWithValue(error);
    }
  }
);
