import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import { giftAssignData } from "../../../../types/redux/gift/giftAssign";

// create
export const createGiftAssignAsync = createAsyncThunk<
  null,
  PostRequest<giftAssignData, CommonOthers>
>(
  "admin-gift/createGiftAssign",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.giftAssign, data);

      reRender(true);
      toast("success", "Gift Assign create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift Assign create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllGiftAssignAsync = createAsyncThunk<
  GetCommonData<giftAssignData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-gift/getAllGiftAssign", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.giftAssign}/data`, {
      params,
    });

    return {
      data: data.data,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Gift Assign fail");
    return rejectWithValue(error);
  }
});

// get all by filter
export const getAllGiftAssignByFilterAsync = createAsyncThunk<
  GetCommonData<giftAssignData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-gift/getAllGiftAssignByFilter",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.giftAssign}/data`, {
        params,
      });

      return {
        data: data.data,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Gift Assign fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateGiftAssignAsync = createAsyncThunk<
  null,
  PostRequest<giftAssignData, GetSingleDataParams>
>(
  "admin-gift/updateGiftAssign",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.giftAssign}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Gift Assign update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift Assign update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteGiftAssignAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-gift/deleteGiftAssign", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.giftAssign}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Gift Assign delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Gift Assign delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singGiftAssignAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-gift/singGiftAssignData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.giftAssign}/${params?.id}`);

    return data.data as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Gift Assign data get fail");
    return rejectWithValue(error);
  }
});
